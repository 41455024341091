'use client';

import Footer from '@/components/layouts/footer';
import LoadingNew from '@/components/layouts/loadingnew';
import Navbar from '@/components/layouts/navbar';
import Overlay from '@/components/layouts/overlay';
import ScrollToTop from '@/components/layouts/scroll-to-top';
import { BKGROUND_IMAGE } from '@/constants/general';
import { useSessionStore } from '@/state/clerkssn';
import { usePathname } from 'next/navigation';
import React, { useEffect, useState } from 'react';

const AuthLayout = ({ children }: { children: React.ReactNode }) => {
    // const isDark = useSelector((state: IRootState) => state.themeConfig.theme === 'dark' || state.themeConfig.isDarkMode);
    const { clearSession } = useSessionStore();
    const [loading, setLoading] = useState(true);
    const pathname = usePathname();

    useEffect(() => {
        clearSession();
        // console.log('Session Clear');
        setLoading(true);
        (async () => {
            await new Promise((resolve) => setTimeout(resolve, 500));

            setLoading(false);
        })();
    }, [pathname]);

    return (
        <>
            <div
                className="relative flex min-h-screen flex-col bg-cover bg-no-repeat"
                style={{
                    backgroundImage: `url(${BKGROUND_IMAGE})`,
                }}
            >
                <Overlay />
                <ScrollToTop />
                <Navbar showButtons={true} isLoading={loading} />

                <div className="flex grow items-center justify-center text-black">{loading ? <LoadingNew /> : children}</div>

                {/* BEGIN FOOTER */}
                <Footer textDark={false} />
                {/* END FOOTER */}
            </div>
        </>
    );
};

export default AuthLayout;
